.spinner-wrapper {
  display: block;
  width: 100%;
  text-align: center;
  margin: 1rem auto;
}
.spinner-wrapper .spinner-border {
  width: 4rem !important;
  height: 4rem !important;
}
.spinner-wrapper.spinner-sm .spinner-border {
  margin: 0 auto;
  width: 1.5rem !important;
  height: 1.5rem !important;
}