#map-list .map-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem;
  padding-right: 0;
  font-size: 1rem;
  color: #333333;
  font-weight: 400;
}
#map-list .map-heading .btn-back * {
  font-weight: 600;
}
@media (min-width: 992px) {
#map-list .map-heading {
    font-size: 1.25rem;
}
}
#map-list .map-wrapper,
#map-list .slick-wrapper {
  margin-bottom: 1.5rem;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
#map-list .map-wrapper,
#map-list .slick-wrapper {
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
}
#map-list .map-wrapper.map-wrapper,
#map-list .slick-wrapper.map-wrapper {
    padding-left: 0;
}
}
#map-list .vue-map-container {
  height: 260px;
}
#map-list .slick-container {
  position: relative;
  overflow: hidden;
}
@media (max-width: 1599.98px) {
#map-list .slick-container {
    margin-top: 20px;
}
}
@media (min-width: 1200px) {
#map-list .slick-container {
    padding-top: 30px;
    padding-bottom: 30px;
}
}
#map-list .property__box {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 992px) {
#map-list .property__box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
#map-list .property__inner {
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  min-height: 500px;
  margin-bottom: 0;
}
@media (max-width: 1599.98px) {
#map-list .property__inner {
    margin-bottom: 0;
}
}
#map-list .property__name {
  min-height: 0px;
}
@media (max-width: 991.98px) {
#map-list .property__name {
    font-size: 14px;
}
}
@media (max-width: 991.98px) {
#map-list .property__link {
    display: none;
}
}
#map-list .slick-arrow {
  position: absolute;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.45);
  text-align: center;
}
#map-list .slick-slider:not(.slick-vertical) .property__box {
  width: 300px;
  max-width: 70vw;
  margin: 0 0.5rem;
}
#map-list .slick-slider:not(.slick-vertical) .slick-slide {
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 30px;
}
#map-list .slick-slider:not(.slick-vertical) .property__inner {
  margin-bottom: 0;
}
#map-list .slick-slider:not(.slick-vertical) .pad-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#map-list .slick-slider:not(.slick-vertical) .slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
#map-list .slick-slider:not(.slick-vertical) .slick-slide {
  position: relative;
  height: auto;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#map-list .slick-slider:not(.slick-vertical) .slick-slide:not(.slick-current) {
  opacity: 0.5;
}
#map-list .slick-slider:not(.slick-vertical) .slick-slide.slick-current .pad-wrapper {
  display: none !important;
}
#map-list .slick-slider:not(.slick-vertical) .slick-slide > div, #map-list .slick-slider:not(.slick-vertical) .slick-slide > div > div {
  height: 100%;
}
#map-list .slick-slider:not(.slick-vertical) ~ #slick-prev {
  left: 0;
  bottom: 0;
  top: 0;
  width: 40px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
}
#map-list .slick-slider:not(.slick-vertical) ~ #slick-next {
  right: 0;
  bottom: 0;
  top: 0;
  width: 40px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
}
@media (min-width: 992px) {
#map-list .vue-map-container {
    height: 700px;
}
#map-list .property__box {
    height: 700px;
    padding: 2rem 0;
}
}
#map-list .slick-slider.slick-vertical {
  height: 100%;
}
#map-list .slick-slider.slick-vertical .property__box {
  margin-right: 0.5rem;
}
#map-list .slick-slider.slick-vertical ~ #slick-prev {
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
}
#map-list .slick-slider.slick-vertical ~ #slick-prev svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
#map-list .slick-slider.slick-vertical ~ #slick-next {
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
}
#map-list .slick-slider.slick-vertical ~ #slick-next svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
#map-list .slick-slider.slick-vertical .slick-slide {
  padding: 15px 10px 15px 5px;
}
#map-list .property__name,
#map-list .property__prices-total {
  font-size: 18px;
}
#map-list .property__city {
  font-size: 14px;
  margin-top: 12px;
}
#map-list .property__rates {
  margin-top: 0;
}
#map-list .property__amenities .amenity {
  width: 50%;
  font-size: 12px;
  margin-bottom: 4px;
}
#map-list .property__desc {
  border: 0;
  margin-top: 0;
  font-size: 14px;
  padding-top: 0;
  margin-bottom: 1rem;
}
#map-list #slick-prev,
#map-list #slick-next {
  margin: 0 auto;
}
@media (min-width: 992px) {
#map-list .map-wrapper {
    padding: 0 0 0 0;
}
#map-list .property__info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#map-list .property__thumb {
    width: 100%;
    min-height: 180px;
    height: auto;
}
#map-list .boxed {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px;
}
#map-list .boxed .desc,
#map-list .boxed .side {
    width: 100%;
    margin: 0;
    padding: 0;
}
#map-list .boxed .desc:before,
#map-list .boxed .side:before {
    display: none;
}
#map-list #property-prices {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
#map-list #property-prices hr {
    display: none;
}
#map-list #property-prices button {
    width: 100%;
    margin-top: 30px;
}
#map-list .property__resume,
#map-list .property__prices-occupancy {
    width: 50%;
}
#map-list .property__prices-per-night {
    margin-bottom: 0;
}
}
@media (min-width: 1200px) {
#map-list #slick-prev {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
#map-list #slick-next {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
}
@media (min-width: 992px) {
#app.map-list #appHeader {
    display: none;
}
}